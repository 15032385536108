<template>
  <div id="details">
    <img src="../../assets/dynamic/bgc2.png" alt="" class="banner" />
    <div class="container">
      <div class="info">
        <h1><i class="el-icon-back" @click="back"></i>{{ info.title }}</h1>
        <p>发表时间：{{ info.updateTime }}</p>
      </div>
      <div id="content"></div>
      
    </div>
    <img src="../../assets/dynamic/bgc1.png" alt="" class="bgc1" />
  </div>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {};
  },
  mounted() {
    let content = document.querySelector("#content");
    content.innerHTML += this.info.content;
  },
  methods: {
    back() {
      this.$emit("func");
    },
  },
};
</script>

<style lang="less" scoped>
#details {
  .banner {
    width: 100%;
    display: flex;
  }
  .container {
    width: 80%;
    // height: 1077px;
    // background-color: pink;
    margin: auto;
    .info {
      padding: 30px 0;
      border-bottom: 1px solid rgba(229, 229, 229, 1);
      h1 {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 2.125rem;
        color: rgba(0, 0, 0, 1);
        position: relative;

        i {
          font-size: 30px;
          position: absolute;
          left: -40px;
        }
      }
      p {
        text-align: left;
        color: rgba(128, 128, 128, 1);
        font-size: 14px;
        margin-top: 15px;
      }
    }
    #content {
      margin-top: 30px;
      text-align: left;
      color: rgba(56, 56, 56, 1);
      line-height: 35px;
      font-size: 16px;
    }
  }
  .bgc1 {
    width: 100%;
    display: flex;
  }
}
@media screen and (max-width: 1200px) {
  #details {
    .container {
      width: 92%;
      padding: 20px 0;

      margin: auto;
      .info {
        padding: 10px 0;
        h1 {
          display: flex;
          align-items: center;
          font-size: 16px;
          position: relative;
          i {
            font-size: 12px;
            position: absolute;
            left: -12px;
          }
        }
        p {
          font-size: 12px;
        }
      }
      #content {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}
</style>
