<template>
  <div id="dynamic">
    <div class="pc" v-if="state">
      <img src="../../assets/dynamic/banner.png" alt="" class="banner" />
      <div class="container">
        <h1>思可优动态</h1>
        <div><img src="../../assets/home/line.png" alt="" class="" /></div>
        <p>专注于公司实时新闻动态，最新了解</p>
        <div class="content">
          <div
            class="box"
            v-for="(item, index) in list"
            :key="index"
            @click="detail(item)"
          >
            <div>
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="title">
              <h3>{{ item.title }}</h3>
              <p class="info_content">
                {{ del_p(item.content) }}
              </p>
              <div class="icon">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page="pageNum"
            layout="prev, pager, next, jumper"
            :page-count="Number(pages)"
          >
          </el-pagination>
        </div>
      </div>
      <img src="../../assets/dynamic/bgc1.png" alt="" class="bgc1" />
    </div>
    <div class="mobile" v-if="state">
      <img src="../../assets/dynamic/banner.png" alt="" class="banner" />
      <div class="container">
        <div class="box" v-for="(item, index) in list" :key="index">
          <h3>{{ item.title }}</h3>
          <p>{{ item.updateTime }}</p>
          <p class="info_content">{{ del_p(item.content) }}</p>
          <div>
            <img :src="item.imgUrl" alt="" />
          </div>
          <div class="button">
            <el-button size="mini" @click="detail(item)"
              >查看详情<i class="el-icon-d-arrow-right"></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Details :info="info" @func="getMsgFormSon"></Details>
    </div>
  </div>
</template>

<script>
import Details from "./details";
import { get_dynamic } from "@/api/all";

export default {
  components: {
    Details,
  },
  data() {
    return {
      state: true,
      list: [],
      pageNum: 1,
      pageSize: 3,
      pages: "",
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    getlist() {
      get_dynamic({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.code == 0) {
            this.list = res.data.list;
            this.pages = res.data.pages;
            this.pageNum = res.data.pageNum;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detail(item) {
      console.log(item);
      this.state = false;
      this.info = item;
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getlist();
    },
    getMsgFormSon() {
      this.state = true;
      this.getlist();
    },

    del_p(item) {
      let str = item.replace(/<\/?.+?>/g, "");
      var result = str.replace(/&([^&;]+);/g, function (matchStr, b) {
        var entity = {
          quot: '"',
          lt: "<",
          gt: ">",
          apos: "'",
          amp: "&",
          ldquo: "“",
          rdquo: "”",
        };
        var r = entity[b];
        return typeof r === "string" ? r : matchStr;
      });
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
#dynamic {
  .pc {
    .banner {
      width: 100%;
    }

    .container {
      width: 100%;
      height: 1304px;

      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }

      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }

      .content {
        width: 80%;
        margin: auto;
        margin-top: 110px;
        .box {
          display: flex;
          align-items: center;
          padding-bottom: 40px;
          border-bottom: 1px solid rgba(229, 229, 229, 1);
          margin-bottom: 20px;
          cursor: pointer;

          img {
            width: 30.3125rem;
            height: 12.6875rem;
          }

          .title {
            margin-left: 40px;

            h3 {
              font-size: 22px;
              color: rgba(0, 0, 0, 1);
              text-align: left;
              padding-bottom: 20px;
            }

            p {
              text-align: left;
              color: rgba(128, 128, 128, 1);
              // padding-bottom: 20px;
            }

            .icon {
              display: flex;
              justify-content: flex-end;
              margin-right: 10px;
              margin-top: 20px;

              i {
                font-size: 16px;
                font-weight: bold;
                color: rgba(128, 128, 128, 1);
              }
            }
          }
        }
      }

      .pagination {
        width: 80%;
        margin: auto;
        text-align: right;

        .el-pagination {
          margin-right: 40px;
        }
      }
    }

    .bgc1 {
      width: 100%;
      display: flex;
    }
  }
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  #dynamic {
    .pc {
      display: none;
    }

    .mobile {
      display: block;

      .banner {
        width: 100%;
        display: flex;
      }

      .container {
        width: 90%;
        // height: 1304px;
        height: auto;
        margin: auto;
        margin-top: 20px;
        .box {
          margin-top: 15px;
          padding-bottom: 10px;
          h3 {
            font-size: 16px;
            text-align: left;
            color: rgba(0, 0, 0, 1);
          }
          p {
            text-align: left;
            margin: 5px 0;
          }
          div {
            text-align: left;
            img {
              width: 100%;
            }
          }
          .button {
            margin-top: 10px;
          }
          i {
            margin-left: 2px;
          }
        }
      }
    }
  }
}
</style>
